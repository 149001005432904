import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import Select from '@paljs/ui/Select';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import ReactPaginate from 'react-paginate';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  checkNumber,
  apiError,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  buildQuery,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const InputPage = () => {
  const state = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [receiptOptions, setReceiptOptions] = useState([]);
  const [defaultReceipt, setDefaultReceipt] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    authCheck(state.authData);
    getReceipts();
  }, []);

  useEffect(() => {
    getApi();
  }, [page]);

  const handlePagination = (event) => {
    setPage(event.selected + 1);
  };

  const getReceipts = () => {
    axios
      .get(baseUri() + 'grey-receipt-advices', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        let options = [];
        response.data.data.forEach(function (item, index) {
          options.push({ label: item.attributes.receiptNumber, value: item.id });
        });
        setReceiptOptions(options);
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getApi = () => {
    showLoader();
    axios
      .get(
        baseUri() +
          buildQuery(
            'processed-greys?populate=*&sort[0]=id:desc&pagination[page]=' + page + '&pagination[pageSize]=20',
            tableFilter,
            filterColumns,
          ),
        {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        setTableData(response.data.data);
        setTotalPage(response.data.meta.pagination.pageCount);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [lotNumber, setLotNumber] = useState('**');
  const [metersProcessed, setMetersProcessed] = useState('');
  const [greyReceiptAdvice, setGreyReceiptAdvice] = useState('');
  const [balesProcessed, setBalesProcessed] = useState('');
  const [remark1, setRemark1] = useState('Adjustment');
  const [remark2, setRemark2] = useState('');
  const [maxMeters, setMaxMeters] = useState(0);
  const [maxBales, setMaxBales] = useState(0);
  const [pickId, setPickId] = useState('');

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'lotNumber':
        setLotNumber(checkNumber(event.target, '5.0'));
        break;
      case 'metersProcessed':
        setMetersProcessed(checkNumber(event.target, '7.0'));
        break;
      case 'balesProcessed':
        setBalesProcessed(checkNumber(event.target, '3.0'));
        break;
      case 'remark2':
        if (event.target.value.length > 200) return;
        setRemark2(event.target.value);
        break;
    }
  };
  const getBalanceApi = (cId, current = 0) => {
    showLoader();
    axios
      .get(baseUri() + `processed-grey/balance-meters?greyReceiptAdvice=${cId}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        document.getElementsByClassName('balanceMeters')[0].innerHTML =
          '* Available: ' + (parseInt(response.data.data.balanceMeters) + parseInt(current));
        setMaxMeters(parseInt(response.data.data.balanceMeters) + parseInt(current));
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const getBalBalanceApi = (cId, current = 0) => {
    showLoader();
    axios
      .get(baseUri() + `processed-grey/balance-bales?greyReceiptAdvice=${cId}`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        document.getElementsByClassName('balanceBales')[0].innerHTML =
          '* Available: ' + (parseInt(response.data.data.balanceBales) + parseInt(current));
        setMaxBales(parseInt(response.data.data.balanceBales) + parseInt(current));
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const receiptCodeChange = (event) => {
    setGreyReceiptAdvice(event.value);
    setDefaultReceipt({ label: event.label, value: event.value });
    pullDownReset('greyReceiptAdvice');
    document.getElementsByClassName('balanceMeters')[0].innerHTML = '';
    document.getElementsByClassName('balanceBales')[0].innerHTML = '';
    getBalanceApi(event.value);
    getBalBalanceApi(event.value);
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setLotNumber('**');
    setMetersProcessed('');
    setPickId('');
    setDefaultReceipt([]);
    setGreyReceiptAdvice('');
    setBalesProcessed('');
    setRemark1('Adjustment');
    setRemark2('');
    resetRequired();
    document.getElementsByClassName('balanceMeters')[0].innerHTML = '';
    document.getElementsByClassName('balanceBales')[0].innerHTML = '';
  };

  const postApi = () => {
    const postData = {
      data: {
        metersProcessed,
        greyReceiptAdvice,
        balesProcessed,
        remark1,
        remark2,
      },
    };
    let validate = requireCheck(postData);
    if (!validate) {
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] == '') {
        delete postData['data'][key];
      }
    }
    if (metersProcessed > maxMeters) {
      apiError('Available Meters is ' + maxMeters, 'processedMax');
      return false;
    }

    if (balesProcessed > maxBales) {
      apiError('Available Bales is ' + maxBales, 'balesProcessed');
      return false;
    }

    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'processed-greys/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          apiError(error.response.data.error.message, 'processedMax');
          hideLoader();
        });
    } else {
      axios
        .post(baseUri() + 'processed-greys/', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          apiError(error.response.data.error.message, 'processedMax');
          hideLoader();
        });
    }
  };

  const editProcess = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + `processed-greys/${id}?populate=*`, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        response = response.data.data;
        setSave(pageAccess.edit === 1);
        setLotNumber(response.id);
        setMetersProcessed(response.attributes.metersProcessed);
        setRemark1(response.attributes.remark1);
        setRemark2(response.attributes.remark2);
        setBalesProcessed(response.attributes.balesProcessed);
        setDefaultReceipt({
          label: response.attributes.greyReceiptAdvice.data.attributes.receiptNumber,
          value: response.attributes.greyReceiptAdvice.data.id,
        });
        setGreyReceiptAdvice(response.attributes.greyReceiptAdvice.data.id);
        setPickId(response.id);
        getBalanceApi(response.attributes.greyReceiptAdvice.data.id, response.attributes.metersProcessed ?? 0);
        getBalBalanceApi(response.attributes.greyReceiptAdvice.data.id, response.attributes.balesProcessed ?? 0);
        hideLoader();
      })
      .catch((error) => {});
  };

  const deleteProcess = (id) => {
    const removeGroup = window.confirm('Would you like to delete this Process?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'processed-greys/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editProcess(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteProcess(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Lot No.',
      selector: (row) => `${row.attributes.jobCard.data ? row.attributes.jobCard.data.id : 0}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'GRA',
      selector: (row) =>
        `${
          row.attributes.greyReceiptAdvice.data ? row.attributes.greyReceiptAdvice.data.attributes.receiptNumber : ''
        }`,
      sortable: true,
      maxWidth: '40%',
    },
    {
      name: 'Processed Meter',
      selector: (row) => numFormatter(row.attributes.metersProcessed),
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Processed Bales',
      selector: (row) => numFormatter(row.attributes.balesProcessed),
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Remark 1',
      selector: (row) => row.attributes.remark1,
      sortable: true,
      maxWidth: '20%',
    },
  ];

  const filterColumns = ['greyReceiptAdvice.receiptNumber', 'metersProcessed', 'jobCard.id', 'balesProcessed'];

  return (
    <>
      <SEO title="GRA Process" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>GRA Process</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Lot Number</label>
                  <Input fullWidth>
                    <input
                      type="text"
                      value={lotNumber}
                      name="lotNumber"
                      maxLength="5"
                      disabled
                      className="lotNumber"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Grey Receipt <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    onChange={(e) => receiptCodeChange(e)}
                    options={receiptOptions}
                    name="greyReceiptAdvice"
                    id="greyReceiptAdvice"
                    placeholder="Grey Receipt *"
                    value={defaultReceipt}
                    className="pull-down require"
                  />
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Meters Processed <span className="green balanceMeters">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="Meters Processed *"
                      name="metersProcessed"
                      maxLength="7"
                      value={metersProcessed}
                      className="metersProcessed processedMax require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Bales Processed <span className="green balanceBales">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="Bales Processed *"
                      name="balesProcessed"
                      maxLength="3"
                      value={balesProcessed}
                      className="balesProcessed require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Remark 1 <span className="green balanceBales">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      onChange={(e) => handleChange(e)}
                      placeholder="Remark 1 *"
                      name="remark1"
                      readOnly
                      value={remark1}
                      className="remark1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Remark 2</label>
                  <Input fullWidth size="Small">
                    <textarea
                      onChange={(e) => handleChange(e)}
                      placeholder="Remark 2"
                      name="remark2"
                      maxLength="200"
                      value={remark2}
                      className="remark2"
                    />
                  </Input>
                </Col>
                <Col className="pull-right" breakPoint={{ xs: 12, md: 12 }}>
                  <Button
                    status="Success"
                    type="button"
                    disabled={!isSave}
                    className="btnrad"
                    shape=""
                    halfWidth
                    onClick={postApi}
                  >
                    Save
                  </Button>
                  &nbsp; &nbsp;
                  <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>GRA Process</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => setTableFilter(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <Button
                onClick={() => {
                  if (page == 1) {
                    getApi();
                  } else {
                    setPage(1);
                  }
                }}
              >
                Filter
              </Button>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
              />
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePagination}
                pageRangeDisplayed={5}
                pageCount={totalPage}
                previousLabel="Previous"
                renderOnZeroPageCount={null}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default InputPage;
